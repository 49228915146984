<template>
  <div v-if="showBanner" class="px-6">
    <div class="rounded-lg bg-slate-50 p-4">
      <h2 class="mb-3 text-sm/4 font-medium">
        {{ $t('company-factory.approved-factory.banner-title') }}
      </h2>
      <div class="grid grid-cols-2 gap-3">
        <div
          v-for="factory in factoryStore.factories"
          :key="factory.id"
          class="text-sm/4"
        >
          <span>{{ factory.code }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useCompanyFactoryStore } from '@/stores';
import { CompanyFactory, ListingType } from '@/types';
import { computed } from 'vue';

const props = defineProps<{
  listingType: ListingType;
  factories: CompanyFactory[];
}>();

const factoryStore = useCompanyFactoryStore();

const showBanner = computed(() => props.factories.length > 0);
</script>
