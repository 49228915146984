import isApprovedListing from '@/filters/listing/is-approved-listing';
import addOrUpdateListing from '@/notifications/common/add-or-update-listing';
import { ListingCreated } from '@/notifications/types';
import { useProductStore, useToastStore } from '@/stores';
import { format } from '@/translations';
import { t } from 'i18next';

const newListingFromApprovedFactoryNotification = (event: ListingCreated) => {
  const listing = event.listing;

  const shouldNotify = isApprovedListing(listing);

  if (!shouldNotify) {
    return;
  }

  const product = useProductStore().findById(listing.productId);

  if (!product) {
    return;
  }

  useToastStore().success({
    title: t('notifications.listing-created.title', { type: listing.type }),
    message: [
      format(listing.volume, 'volume'),
      Object.entries(listing.specifications)[0]?.[1][0],
      format(listing.shipment, 'shipment'),
    ].join(', '),
    link: `/overview/products/${product.slug}/listings/${listing.id}`,
    timeout: 7000,
  });
};

export default [
  addOrUpdateListing<ListingCreated>,
  newListingFromApprovedFactoryNotification,
];
