<template>
  <FactoryBanner
    v-if="showBanner"
    :listing-type="listing.type"
    :factories="companyFactories"
  />
</template>

<script setup lang="ts">
import FactoryBanner from '@/components/FactoryBanner.vue';
import isApprovedListingFilter from '@/filters/listing/is-approved-listing';
import {
  useApprovedFactoryStore,
  useCompanyFactoryStore,
  useUserStore,
} from '@/stores';
import { ListingPreview } from '@/types';
import { computed } from 'vue';

const props = defineProps<{
  listing: ListingPreview;
}>();

const userStore = useUserStore();
const approvedFactoryStore = useApprovedFactoryStore();
const companyFactoryStore = useCompanyFactoryStore();

const isYourListing = computed(() => {
  return userStore.isMyCompany(props.listing.owner.id);
});

const isApprovedListing = computed(() => {
  return isApprovedListingFilter(props.listing);
});

const isUserSeller = computed(() => {
  return (
    (props.listing.type === 'BID' && isApprovedListing.value) ||
    (props.listing.type === 'OFFER' && isYourListing.value)
  );
});

const showBanner = computed(
  () =>
    isUserSeller.value &&
    companyFactories.value.length !== companyFactoryStore.factories.length
);

const companyFactories = computed(() => {
  if (isApprovedListing.value) {
    return approvedFactories.value;
  }

  return companyFactoryStore.filter(props.listing.factories);
});

const approvedFactories = computed(() => {
  if (isYourListing.value) {
    return [];
  }

  return approvedFactoryStore.filter(props.listing.factories);
});
</script>
