import isBidIncoterm from '@/filters/shipment/is-bid-incoterm';
import isOfferIncoterm from '@/filters/shipment/is-offer-incoterm';
import { Incoterm, ListingType } from '@/types';

export default (type: ListingType, incoterm: Incoterm) => {
  if (type === 'OFFER') {
    return isOfferIncoterm(incoterm);
  }

  return isBidIncoterm(incoterm);
};
