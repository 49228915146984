import { useApi } from '@/api';
import { useUserStore } from '@/stores';
import { NavigationGuardWithThis } from 'vue-router';

export const authenticated: NavigationGuardWithThis<undefined> =
  async function (to) {
    const api = useApi();
    const userStore = useUserStore();

    if (!userStore.token) {
      return { name: 'login', query: { from: to.fullPath } };
    }

    if (!userStore.user) {
      try {
        const { user, accessToken } = await api.user.refresh();
        userStore.setTokenAndUser(user, accessToken);
      } catch {
        userStore.token = '';
        return { name: 'login', query: { from: to.fullPath } };
      }
    }
  };

export const guest: NavigationGuardWithThis<undefined> = function () {
  const userStore = useUserStore();

  if (userStore.authenticated) {
    return { name: 'overview' };
  }
};
