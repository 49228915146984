import { useApi } from '@/api';
import { useUserStore } from '@/stores';
import { CompanyFactory } from '@/types/company';
import { Product } from '@/types/product';
import { captureException, withScope } from '@sentry/vue';
import { isEmpty } from 'lodash';
import { defineStore } from 'pinia';
import { ref } from 'vue';

export const useCompanyFactoryStore = defineStore('companyFactory', () => {
  const api = useApi();

  const factories = ref<CompanyFactory[]>([]);

  async function load() {
    if (!useUserStore().hasPermission('CUSTOMER/FACTORY.GET')) {
      return;
    }

    await api.company
      .listFactories()
      .then((loadedFactories) => (factories.value = loadedFactories))
      .catch((error) =>
        withScope((scope) => {
          scope.setLevel('warning');
          captureException(
            new Error('Failed to load company factories', { cause: error })
          );
        })
      );
  }

  function filter(ids: number[]) {
    return factories.value.filter((factory) => ids.includes(factory.id));
  }

  function filterByProduct(product: Product) {
    return factories.value.filter((factory) =>
      factory.products.includes(product.id)
    );
  }

  function hasAny(factoryIds: number[]) {
    const companyFactoryIds = useCompanyFactoryStore().factories.map(
      (factory) => factory.id
    );

    if (isEmpty(companyFactoryIds) || isEmpty(factoryIds)) {
      return false;
    }

    return factoryIds.some((factoryId) =>
      companyFactoryIds.includes(factoryId)
    );
  }

  function clear() {
    factories.value = [];
  }

  return {
    clear,
    factories,
    filter,
    filterByProduct,
    hasAny,
    load,
  };
});
