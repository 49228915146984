<template>
  <div class="mb-8 rounded-xl bg-white py-6">
    <Heading tag="h2" :text="product.name" class="max-w-48" />
    <hr class="my-8" />
    <div class="grid grid-cols-2">
      <CreateListingPreviewCardDetail
        :title="$t('listing-card.price-mt')"
        :text="`€${form.unitPriceValue ?? ''}`"
        size="xl"
      />
      <CreateListingPreviewCardDetail
        :title="$t('listing-card.volume')"
        :text="`${form.volumeAmount ?? ''} MT`"
        size="xl"
      />
    </div>
    <hr class="my-8" />
    <div class="grid grid-cols-3">
      <CreateListingPreviewCardDetail
        v-if="product.slug === 'skimmed-milk-powder'"
        class="mb-8"
        :title="$t(`product.main-spec.${product.slug}`)"
        :text="heat"
      />
      <CreateListingPreviewCardDetail
        v-else
        class="mb-8"
        :title="$t(`product.main-spec.${product.slug}`)"
        :text="type"
      />
      <CreateListingPreviewCardDetail
        class="mb-8"
        :title="$t('listing-card.age')"
        :text="age"
      />
      <div></div>
      <CreateListingPreviewCardDetail
        :title="$t('listing-card.incoterm')"
        :text="previewDescription(form.incoterm)"
      />
      <CreateListingPreviewCardDetail
        :title="$t('listing-card.delivery-to')"
        :text="previewDescription(form.deliveryLocation?.name)"
      />
      <CreateListingPreviewCardDetail
        :title="$t('listing-card.delivery')"
        :text="
          form.latestDelivery
            ? $format(
                new Date(form.latestDelivery),
                'datetime',
                formats.date.LONG_YEAR_MONTH
              )
            : '-'
        "
      />
    </div>
    <div v-if="form.factories && form.factories.length > 0">
      <hr class="my-8" />
      <CreateListingPreviewCardDetail
        :title="$t('listing-card.factories')"
        :text="form.factories.map((factory) => factory.value.code).join(', ')"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import CreateListingPreviewCardDetail from '@/components/CreateListingPreviewCardDetail.vue';
import Heading from '@/components/Heading.vue';
import { previewDescription } from '@/data/formatters';
import { formats } from '@/translations';
import { ListingForm } from '@/types';
import { Product } from '@/types/product';
import { computed, PropType } from 'vue';

const props = defineProps({
  form: {
    type: Object as PropType<ListingForm>,
    required: true,
  },
  product: {
    type: Object as PropType<Product>,
    required: true,
  },
});

const selectedSpecDescription = (label: string) => {
  const selected = props.form.specs.filter((selectedSpec) => {
    return selectedSpec.description.includes(label);
  });

  if (selected.length > 0 && selected[0]?.options?.length > 0) {
    return previewDescription(selected[0]?.options[0]?.description);
  }

  return '-';
};

const heat = computed(() => selectedSpecDescription('Heat'));
const type = computed(() => selectedSpecDescription('Type'));
const age = computed(() => selectedSpecDescription('Product age'));

defineEmits(['submit']);
</script>
