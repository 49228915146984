import { useShipmentStore } from '@/stores';
import engb from '@/translations/en-gb.json';
import { UnitPrice, Volume } from '@/types/common';
import { ListingShipment } from '@/types/shipment';
import i18next, {
  ExistsFunction,
  InterpolationOptions,
  TFunction,
} from 'i18next';
import { Ref, ref } from 'vue';

const supportedLngs = ['en', 'en-GB'] as const;
type SupportedLanguages = (typeof supportedLngs)[number];
const language = ref<SupportedLanguages>('en-GB');

i18next
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    lng: language.value,
    fallbackLng: false,
    interpolation: {
      // Vue already escapes for us.
      escapeValue: false,
    },
    resources: {
      'en-GB': {
        translation: engb,
      },
    },
  });

// Use browser language for number formatter to show preferred format for all clients
i18next.services.formatter?.add(
  'number',
  (value, lng, options: Intl.NumberFormatOptions | undefined) => {
    return new Intl.NumberFormat(lng, options).format(value);
  }
);

i18next.services.formatter?.add(
  'price',
  (price: UnitPrice) => `${format(price.value, 'number')} ${price.currency}`
);

i18next.services.formatter?.add('money', (value: UnitPrice | number) => {
  if (typeof value === 'number') {
    return `€${format(value, 'number')}`;
  } else {
    return `€${format(value.value, 'number')}`;
  }
});

i18next.services.formatter?.add(
  'volume',
  (volume: Volume) => `${format(volume.amount, 'number')} ${volume.unit}`
);

i18next.services.formatter?.add('shipment', (shipment: ListingShipment) => {
  return useShipmentStore().getIncotermWithLocation(shipment);
});

/* the 'daterange' formatter returns a string like 'Jan - Mar 22' where the left
 * side is the start value and the right side is the end value. You must pass it a
 * DateTimeFormat option to it to render the month or year the way you wish too.
 * You can see an implementation within RetailConsumptionGraph (vesper-web-app).
 */
i18next.services.formatter?.add(
  'daterange',
  (
    value: { start: Date; end: Date },
    lng,
    options: Intl.DateTimeFormatOptions | undefined
  ) => {
    return new Intl.DateTimeFormat(lng, options).formatRange(
      value.start,
      value.end
    );
  }
);

i18next.services.formatter?.add('time-ago', (datetime: string) => {
  const tsDateTime = new Date(datetime).getTime();
  const tsNowTime = new Date().getTime();
  const seconds = Math.floor((tsNowTime - tsDateTime) / 1000);
  let interval = seconds / 31536000;

  if (interval > 1) {
    return t('common.years-ago', { interval: Math.floor(interval) });
  }

  interval = seconds / 2592000;

  if (interval > 1) {
    return t('common.months-ago', { interval: Math.floor(interval) });
  }

  interval = seconds / 86400;

  if (interval > 1) {
    return t('common.days-ago', { interval: Math.floor(interval) });
  }

  interval = seconds / 3600;

  if (interval > 1) {
    return t('common.hours-ago', { interval: Math.floor(interval) });
  }

  interval = seconds / 60;

  if (interval > 1) {
    return t('common.minutes-ago', { interval: Math.floor(interval) });
  }

  return t('common.minutes-ago', { interval: 1 });
});

const { exists } = i18next;

function t(...args: Parameters<TFunction>) {
  // Watch the language to re-render the strings if it changes.
  // eslint-disable-next-line @typescript-eslint/no-unused-expressions
  language.value;

  return i18next.t(...args);
}

export function format(
  value: unknown,
  format?: string,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  options: InterpolationOptions & Record<string, any> = {}
): string {
  return i18next.format(value, format, language.value, options);
}

export const useI18n = (): {
  t: TFunction;
  format: typeof format;
  exists: ExistsFunction;
  language: Ref<SupportedLanguages>;
} => {
  return {
    t: t as TFunction,
    format,
    exists,
    language,
  };
};

export const formats = {
  date: {
    YEAR_MONTH_DAY: {
      day: '2-digit',
      month: 'short',
      year: '2-digit',
    },
    YEAR_MONTH: { month: 'short', year: '2-digit' },
    LONG_YEAR_MONTH: { month: 'short', year: 'numeric' },
    MONTH: { month: 'short' },
    MONTH_DAY: { month: 'short', day: '2-digit' },
    YEAR_SHORT: { year: '2-digit' },
    YEAR: { year: 'numeric' },
    HOUR_MINUTE: { hour: '2-digit', minute: '2-digit' },
  },
} as const;
