import { RouteRecordRaw } from 'vue-router';

export const appRoutes: RouteRecordRaw[] = [
  {
    path: '',
    redirect: { name: 'overview' },
  },
  {
    path: '/overview/products/:productSlug?',
    name: 'overview',
    component: () => import('@/pages/Overview.vue'),
  },
  {
    path: '/overview/products/:productSlug/listings/:id',
    name: 'listings',
    component: () => import('@/pages/Overview.vue'),
  },
  {
    path: '/deals',
    name: 'deals',
    component: () => import('@/pages/Deals.vue'),
  },
  {
    path: '/help-and-support',
    name: 'help-and-support',
    component: () => import('@/pages/HelpAndSupport.vue'),
  },
  {
    path: '/my-account',
    name: 'my-account',
    component: () => import('@/pages/MyAccount.vue'),
  },
];
