<template>
  <div class="flex justify-between">
    <p class="text-sm font-semibold">{{ spec.description }}</p>
    <div class="me-4 grid grid-cols-2 gap-4">
      <div v-for="(option, index) in spec.options" :key="option.id">
        <input
          v-model="selected[index]"
          type="checkbox"
          :value="option.id"
          @change="setModelValue()"
        />
        {{ option.description }}
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ProductSpec } from '@/types/product-specs';
import { PropType, reactive } from 'vue';

const emit = defineEmits(['update:modelValue']);

const props = defineProps({
  spec: {
    type: Object as PropType<ProductSpec>,
    required: true,
  },
});

const selected: Record<number, boolean> = reactive({});

function setModelValue(): void {
  const model = Object.entries(selected)
    .filter((item) => item[1])
    .map((item) => parseInt(item[0]));

  const specs = model.map((index) => props.spec?.options[index]);

  emit('update:modelValue', specs);
}
</script>
