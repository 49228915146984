<template>
  <div
    class="flex max-w-60 items-center rounded-md bg-vesper-neutral px-3 py-2 text-xs text-white"
  >
    <div
      class="mr-3 grid size-2.5 place-content-center rounded-full bg-green-500/25"
      :class="{
        'animate-pulse': !isIdle,
      }"
    >
      <div
        class="size-1.5 rounded-full"
        :class="{
          'bg-green-500': marketOpen,
          'bg-red-500': !marketOpen,
          'bg-yellow-500': isIdle,
        }"
      ></div>
    </div>
    <div v-if="!market">
      <p>{{ $t('market-indicator.market-is-closed') }}</p>
    </div>
    <div v-else-if="isIdle">
      <p>{{ $t('market-indicator.app-is-idle') }}</p>
    </div>
    <div v-else class="mr-2 flex space-x-1">
      <p>{{ $t('market-indicator.market-is') }}</p>
      <p class="">
        {{
          marketOpen
            ? $t('market-indicator.open')
            : $t('market-indicator.opening-in')
        }}
      </p>
    </div>
    <div v-if="market && !isIdle" class="ml-auto w-[75px] text-nowrap">
      <span>{{ days }}</span>
      <span class="opacity-50">{{ $t('market-indicator.divider') }}</span>
      <span>{{ String(hours).padStart(2, '0') }}</span>
      <span class="opacity-50">{{ $t('market-indicator.divider') }}</span>
      <span>{{ String(minutes).padStart(2, '0') }}</span>
      <span class="opacity-50">{{ $t('market-indicator.divider') }}</span>
      <span class="opacity-50">{{ String(seconds).padStart(2, '0') }}</span>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useMarketStore, useUserStore } from '@/stores';
import { computed, onUnmounted, ref, watch } from 'vue';

const userStore = useUserStore();
const marketStore = useMarketStore();
marketStore.load();

const market = computed(() => marketStore.market);
const marketOpen = computed(() => marketStore.marketOpen);
const dateOfReference = computed(() => marketStore.dateOfReference);
const isIdle = computed(() => userStore.isIdle);

const days = ref(0);
const hours = ref(0);
const minutes = ref(0);
const seconds = ref(0);

const updateCountdown = () => {
  if (marketStore.market === null) return;

  const now = new Date();
  const timeTillMarketIsOpen = dateOfReference.value.getTime() - now.getTime();

  if (timeTillMarketIsOpen <= 0) {
    // Countdown is over
    clearInterval(interval);
    marketStore.load();
    return;
  }

  days.value = Math.floor(timeTillMarketIsOpen / (1000 * 60 * 60 * 24));
  hours.value = Math.floor(
    (timeTillMarketIsOpen % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
  );
  minutes.value = Math.floor(
    (timeTillMarketIsOpen % (1000 * 60 * 60)) / (1000 * 60)
  );
  seconds.value = Math.floor((timeTillMarketIsOpen % (1000 * 60)) / 1000);
};

let interval = setInterval(updateCountdown, 1000);

const restart = () => {
  clearInterval(interval);
  interval = setInterval(updateCountdown, 1000);
  updateCountdown();
};

watch(
  () => marketStore.market,
  () => {
    restart();
  }
);

onUnmounted(() => clearInterval(interval));
</script>
