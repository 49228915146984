<template>
  <div
    class="grid place-content-center rounded-md px-1 py-0.5 text-[10px] font-medium"
    :class="{
      'bg-vesper-accent text-white': byYou,
      'bg-emerald-100': isBuyer,
      'bg-red-100': isProducer,
      'bg-amber-100': isTrader,
    }"
  >
    {{ byYou ? $t('listing-preview.by-you') : $t(`overview.${type}`) }}
  </div>
</template>

<script setup lang="ts">
import { CompanyType } from '@/types/company';

const props = defineProps<{
  byYou: boolean;
  type: CompanyType;
}>();

const isBuyer = !props.byYou && props.type === 'BUYER';
const isProducer = !props.byYou && props.type === 'PRODUCER';
const isTrader = !props.byYou && props.type === 'TRADER';
</script>
