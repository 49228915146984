import { Api } from '@/api/types';
import { UserStore } from '@/stores';
import { LoginResponse } from '@/types/user';

export class User {
  public constructor(
    protected readonly client: Api,
    protected readonly userStore: () => UserStore
  ) {}

  public login(email: string, password: string): Promise<LoginResponse> {
    return this.client.request('POST', 'auth/login', { email, password });
  }

  public refresh(): Promise<LoginResponse> {
    return this.client.request('post', 'auth/refresh', undefined, {
      headers: {
        Authorization: `Bearer ${this.userStore().token}`,
      },
    });
  }

  public logout(): Promise<void> {
    return this.client.request('post', 'auth/logout', undefined, {
      headers: {
        Authorization: `Bearer ${this.userStore().token}`,
      },
    });
  }
}
