<template>
  <header class="border-b border-vesper-mud">
    <div class="flex w-full items-center px-6 py-5">
      <h2 v-if="!isChildRoute" class="font-medium leading-none">
        {{ $t([`pages.${String(route.name)}`]) }}
      </h2>
      <h2
        v-else-if="route.name === 'overview' || route.name === 'listings'"
        class="font-medium leading-none"
      >
        {{ productStore.product?.name }}
      </h2>
      <div class="ml-auto flex space-x-3">
        <CompanyTypeIndicator
          v-if="userStore.company"
          :by-you="false"
          :type="userStore.company.type"
        />
        <NextMarketEvent />
        <MarketIndicator />
      </div>
    </div>
  </header>
</template>

<script setup lang="ts">
import CompanyTypeIndicator from '@/components/CompanyTypeIndicator.vue';
import MarketIndicator from '@/components/MarketIndicator.vue';
import NextMarketEvent from '@/components/NextMarketEvent.vue';
import { useProductStore, useUserStore } from '@/stores';
import { computed } from 'vue';
import { useRoute } from 'vue-router';

const route = useRoute();
const userStore = useUserStore();
const productStore = useProductStore();

const isChildRoute = computed(() => {
  const segments = route.path.split('/');
  return segments.length > 2;
});
</script>
