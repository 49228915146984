import { useApi } from '@/api';
import router from '@/router';
import { useNegotiationStore } from '@/stores';
import { ListingPreview } from '@/types';

export default <E>(event: E & { listing: ListingPreview }) => {
  const route = router.currentRoute.value;
  const isListingPage = route.name == 'listings';
  const isSameListing = Number(route.params.id) == event.listing.id;

  if (!isListingPage || !isSameListing) {
    return;
  }

  useApi()
    .negotiation.findAllByListing(event.listing.id)
    .then((negotiations) => {
      useNegotiationStore().negotiations[event.listing.id] = negotiations;
    });
};
