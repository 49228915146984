<template>
  <div class="mb-5 px-6">
    <div class="grid grid-cols-3 gap-4 rounded-lg bg-slate-50 p-4">
      <ListingInfoCardBlock
        :tag="$t('listing.shipment.incoterm')"
        :value="listing.shipment.incoterm"
      />
      <ListingInfoCardBlock
        :tag="$t('listing.shipment.delivery-location')"
        :value="
          shipmentStore.getLocationById(listing.shipment.locationId)?.name ?? ''
        "
      />
      <ListingInfoCardBlock
        :tag="$t('listing.shipment.latest-delivery')"
        :value="
          $format(
            new Date(listing.shipment.latestDelivery),
            'datetime',
            formats.date.LONG_YEAR_MONTH
          )
        "
      />
      <!-- update when we have main spec inside the product object-->
      <ListingInfoCardBlock
        :tag="
          $t(
            `product.main-spec.${productStore.findById(listing.productId)?.slug}`
          )
        "
        :value="Object.entries(listing.specifications)[0]?.[1][0]"
      />
      <ListingInfoCardBlock
        :tag="$t('listing-card.age')"
        :value="listing.specifications['Product age']?.[0]"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import ListingInfoCardBlock from '@/components/ListingInfoCardBlock.vue';
import { useProductStore, useShipmentStore } from '@/stores';
import { formats } from '@/translations';
import { ListingPreview } from '@/types';

const shipmentStore = useShipmentStore();
const productStore = useProductStore();

defineProps<{
  listing: ListingPreview;
}>();
</script>
