import companyDealCreatedHandlers from '@/notifications/company/company-deal-created-handlers';
import companyNegotiationPausedHandlers from '@/notifications/company/company-negotiation-paused-handlers';
import companyNegotiationWithdrawnHandlers from '@/notifications/company/company-negotiation-withdrawn-handlers';
import proposalCreatedHandlers from '@/notifications/company/company-proposal-created-handlers';
import { EventHandler } from '@/types/notification';

export const companyEventsHandlers: Record<string, EventHandler[]> = {
  'Marketplace.DealCreated': companyDealCreatedHandlers,
  'Marketplace.NegotiationPaused': companyNegotiationPausedHandlers,
  'Marketplace.ProposalCreated': proposalCreatedHandlers,
  'Marketplace.NegotiationWithdrawn': companyNegotiationWithdrawnHandlers,
};
