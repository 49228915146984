import { DealCreated } from '@/notifications/types';
import { defineStore } from 'pinia';
import { ref } from 'vue';

export const useModalStore = defineStore('modal', () => {
  const isVisible = ref(false);
  const eventData = ref<DealCreated | null>(null);

  const showModal = (event: DealCreated) => {
    eventData.value = event;
    isVisible.value = true;
  };

  const hideModal = () => {
    isVisible.value = false;
  };

  return {
    isVisible,
    eventData,
    showModal,
    hideModal,
  };
});
