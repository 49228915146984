<template>
  <Combobox
    multiple
    :by="compareOptions"
    :model-value="modelValue"
    @update:model-value="emit('update:modelValue', $event)"
  >
    <Float :offset="{ mainAxis: 4, crossAxis: 0 }">
      <DropdownButton
        :as="ComboboxButton"
        :variant="variant"
        class="disabled:cursor-not-allowed disabled:opacity-50"
        :has-values="modelValue.length > 0"
      >
        <span class="truncate">
          {{ label }}
        </span>
      </DropdownButton>
      <ComboboxOptions>
        <DropdownPanel>
          <div class="-mb-px flex w-72 flex-col border-b">
            <label
              class="flex items-center gap-3 rounded-t-md border-b bg-vesper-stone px-4 text-xs/none"
            >
              <Icon
                icon="fa-regular fa-magnifying-glass"
                class="my-auto text-vesper-blue/50"
              />
              <ComboboxInput
                class="block flex-1 bg-transparent py-3 focus:outline-none"
                :display-value="() => query"
                :placeholder="$t('filter.search', { type: 'country' })"
                autocomplete="off"
                @change="query = $event.target.value"
              />
              <button
                v-show="query"
                type="button"
                class="flex size-6 rounded-full bg-vesper-neutral text-white"
                @click="query = ''"
              >
                <Icon icon="fa-solid fa-xmark" class="m-auto" fixed-width />
              </button>
            </label>
            <div
              v-if="filteredGroupedData.length > 0"
              class="flex-1 overflow-auto"
            >
              <GroupMultiSelectOptions
                v-for="(group, index) of filteredGroupedData"
                :key="group.groupKey"
                :model-value="modelValue"
                :group="group"
                :open="groupOpenKey === group.groupKey"
                :display-value="displayValue"
                :is-last="index === filteredGroupedData.length - 1"
                multiple
                @toggle:visible="toggleGroupVisible(group.groupKey)"
                @toggle:selection="deselectSelection(group.groupKey)"
              />
            </div>
            <div
              v-else
              class="py-4 text-center text-sm/none text-vesper-blue/50"
            >
              {{ $t('filter.no-results') }}
            </div>
          </div>
        </DropdownPanel>
      </ComboboxOptions>
    </Float>
  </Combobox>
  <span v-if="errorMessage" class="text-xs text-red-500">
    {{ errorMessage }}
  </span>
</template>

<script
  setup
  lang="ts"
  generic="
    T extends Record<string, unknown> & { id: number },
    TKey extends string
  "
>
import DropdownButton from '@/components/DropdownButton.vue';
import DropdownPanel from '@/components/DropdownPanel.vue';
import Float from '@/components/Float.vue';
import GroupMultiSelectOptions from '@/components/GroupMultiSelectOptions.vue';
import Icon from '@/components/Icon.vue';
import { GroupedCollection, GroupedItem } from '@/types';
import {
  Combobox,
  ComboboxButton,
  ComboboxInput,
  ComboboxOptions,
} from '@headlessui/vue';
import { t } from 'i18next';
import { useField } from 'vee-validate';
import { computed, ref, toRef } from 'vue';

const emit = defineEmits<{
  (e: 'update:modelValue', value: GroupedItem<TKey, T>[]): void;
}>();

const props = defineProps<{
  modelValue: GroupedItem<TKey, T>[];
  groupedData: GroupedCollection<TKey, T>[];
  name: string;
  displayValue: 'code' | 'name';
  label: string;
  variant: 'big' | 'small';
}>();

const groupOpenKey = ref<null | string>(null);

const label = computed(() => {
  const selectedOptions = props.modelValue;

  if (selectedOptions.length === 0) {
    return t(`multi-select.${props.label}`);
  }

  return selectedOptions.length <= 3
    ? selectedOptions
        .map((option) => {
          return `${option.groupKey}:${option.value[props.displayValue]}`;
        })
        .join(', ')
    : t(`multi-select.${props.label}-count`, { count: selectedOptions.length });
});

function toggleGroupVisible(groupBy: string): void {
  groupOpenKey.value = groupOpenKey.value === groupBy ? null : groupBy;
}

function deselectSelection(groupKey: string): void {
  const selectedOptions = props.modelValue;

  if (props.modelValue.some((option) => option.groupKey === groupKey)) {
    const items = selectedOptions.filter(
      (option) => option.groupKey !== groupKey
    );

    emit('update:modelValue', items);
  }
}

function compareOptions(
  optionA: GroupedItem<TKey, T>,
  optionB: GroupedItem<TKey, T>
): boolean {
  return (
    optionA.value.id === optionB.value.id &&
    optionA.groupKey === optionB.groupKey
  );
}

const query = ref('');
const filteredGroupedData = computed(() => {
  if (query.value.trim() === '') {
    return props.groupedData;
  }

  return props.groupedData
    .map((group) => ({
      ...group,
      groupValues: group.groupValues.filter((item) => {
        return String(item[props.displayValue])
          .toLowerCase()
          .includes(query.value.toLowerCase());
      }),
    }))
    .filter((group) => group.groupValues.length > 0);
});

// use `toRef` to create reactive references to `name` prop which is passed to `useField`
// this is important because vee-validate needs to know if the field name changes
// https://vee-validate.logaretm.com/v4/guide/composition-api/caveats
const name = toRef(props, 'name');

// we don't provide any rules here because we are using form-level validation
// https://vee-validate.logaretm.com/v4/guide/validation#form-level-validation
const { errorMessage } = useField(name, undefined, {
  syncVModel: true,
});
</script>
