<template>
  <div
    class="flex h-screen flex-row bg-vesper-offwhite"
    :class="{ 'pl-[72px]': !sidebarStore.sidebarPinned }"
  >
    <AppSidebarNav />
    <div id="app-body" class="grow overflow-y-auto">
      <AppHeader />
      <div class="p-4 lg:px-6 lg:py-5">
        <RouterView />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import AppHeader from '@/components/AppHeader.vue';
import AppSidebarNav from '@/components/AppSidebarNav.vue';
import { initialisePusher } from '@/lib/pusher-initialiser';
import { useSidebarStore } from '@/stores';
import { RouterView } from 'vue-router';

const sidebarStore = useSidebarStore();

// We don't want to connect to Pusher before the user is logged in, so the initialisation happens in here
// as this is the entry point of the application for authenticated users.
initialisePusher();
</script>
