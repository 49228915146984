import fromApprovedFactories from '@/filters/listing/from-approved-factories';
import fromDesiredFactories from '@/filters/listing/from-desired-factories';
import hasAvailableVolume from '@/filters/listing/has-available-volume';
import isBid from '@/filters/listing/is-bid';
import isNotOngoing from '@/filters/listing/is-not-ongoing';
import isOffer from '@/filters/listing/is-offer';
import isPublished from '@/filters/listing/is-published';
import { useFilterStore, useUserStore } from '@/stores';
import { ListingPreview } from '@/types';
import { defineStore } from 'pinia';
import { computed, ref } from 'vue';

export const withoutStats = (listing: ListingPreview) => {
  listing.stats = {
    totalOngoing: 0,
    waitingResponse: 0,
    lowestCounterOffer: undefined,
    highestCounterBid: undefined,
  };

  return listing;
};

export const useListingStore = defineStore('listings', () => {
  const activeHistory = ref<Record<number, number>>({});
  const listings = ref<ListingPreview[]>([]);
  const userStore = useUserStore();
  const filtered = computed(() => useFilterStore().apply(listings.value));

  const setListings = (list: ListingPreview[]) => {
    listings.value = list;
    computeActiveHistory();
  };

  const updateListings = (list: ListingPreview[]) => {
    list.reverse().forEach((listing) => addOrUpdate(listing));
  };

  const addOrUpdate = (listing: ListingPreview) => {
    if (listing.volume.amount <= 0) {
      /**
       * When the listing gets fulfilled, it will be removed from the store by ListingCompleted handlers.
       * Since there is no guarantee which event will be handled first, this if avoids adding to the store
       * again when the listing was previously removed.
       */
      return;
    }

    const index = listings.value.findIndex((item) => item.id === listing.id);
    index >= 0
      ? (listings.value[index] = listing)
      : /**
         * not entirely sure if we should just prepend the missing item or run a
         * sorting function -> listings.value.sort((a, b) => b.id - a.id);
         */
        listings.value.unshift(listing);

    computeActiveHistory();
  };

  const clear = () => {
    listings.value = [];
    activeHistory.value = {};
  };

  const computeActiveHistory = () => {
    active.value.forEach((listing) => {
      activeHistory.value[listing.id] = listing.id;
    });
  };

  const completeListing = (listing: { id: number }) => {
    const index = listings.value.findIndex((item) => item.id === listing.id);

    if (index < 0) {
      // If the listing is not found, then the user likely already refreshed the store before receiving the event.
      return;
    }

    const listingEntry = listings.value[index];
    listingEntry.status = 'COMPLETED';
    listingEntry.volume.amount = 0;
    listings.value[index] = withoutStats(listingEntry);
  };

  const cancelListing = (listing: { id: number }) => {
    const index = listings.value.findIndex((item) => item.id === listing.id);

    if (index < 0) {
      // If the listing is not found, then the user likely already refreshed the store before receiving the event.
      return;
    }

    const listingEntry = listings.value[index];
    listingEntry.status = 'CANCELED';
    listings.value[index] = withoutStats(listingEntry);
  };

  const remove = (listing: { id: number }) => {
    listings.value = listings.value.filter((item) => item.id !== listing.id);
  };

  const withdraw = (listing: ListingPreview) => {
    delete activeHistory.value[listing.id];
    addOrUpdate(withoutStats(listing));
  };

  const isAvailable = (listing: ListingPreview) => {
    return hasAvailableVolume(listing) && isPublished(listing);
  };
  const bestPriceOf = (listing: ListingPreview) => {
    const best = isBid(listing)
      ? // If the bid is mine, the best I can receive is the lowestCounterOffer.
        userStore.isMyCompany(listing.owner.id)
        ? listing.stats.lowestCounterOffer
        : listing.stats.highestCounterBid
      : // If the offer is mine, the best I can receive is the highestCounterBid.
        userStore.isMyCompany(listing.owner.id)
        ? listing.stats.highestCounterBid
        : listing.stats.lowestCounterOffer;

    return best ?? listing.unitPrice;
  };

  const active = computed(() => {
    return filtered.value.filter((listing) => {
      return (
        userStore.isMyCompany(listing.owner.id) ||
        listing.stats.totalOngoing > 0 ||
        activeHistory.value[listing.id] == listing.id
      );
    });
  });

  const isActive = (listing: ListingPreview) => {
    return active.value.some((item: ListingPreview) => {
      return item.id === listing.id;
    });
  };

  const opportunities = computed(() => {
    return filtered.value
      .filter(fromApprovedFactories)
      .filter(fromDesiredFactories)
      .filter(isNotOngoing)
      .filter((listing) => activeHistory.value[listing.id] == undefined);
  });

  const bids = computed(() => filtered.value.filter(isBid));
  const offers = computed(() => filtered.value.filter(isOffer));
  const all = computed(() => filtered.value);

  const publishedBidCount = computed(() => {
    return bids.value.filter(isPublished).length;
  });
  const publishedOfferCount = computed(() => {
    return offers.value.filter(isPublished).length;
  });

  return {
    active,
    addOrUpdate,
    all,
    bestPriceOf,
    bids,
    cancelListing,
    clear,
    completeListing,
    isActive,
    isAvailable,
    listings: filtered,
    offers,
    opportunities,
    publishedBidCount,
    publishedOfferCount,
    remove,
    setListings,
    updateListings,
    withdraw,
  };
});
