import isOffer from '@/filters/listing/is-offer';
import { useApprovedFactoryStore } from '@/stores';
import { ListingPreview } from '@/types';

export default (listing: ListingPreview) => {
  if (!isOffer(listing)) {
    return true;
  }

  return useApprovedFactoryStore().hasAnyByProduct(
    listing.factories,
    listing.productId
  );
};
