<!-- eslint-disable vue/v-on-function-call -->
<template>
  <div
    class="relative flex w-full items-center gap-6 rounded-lg border bg-transparent text-left text-sm"
  >
    <Combobox
      :disabled="disabled"
      :model-value="modelValue"
      :multiple="multiple"
      @update:model-value="emit('update:modelValue', $event)"
    >
      <Float :offset="{ mainAxis: 0 }">
        <ComboboxButton
          v-slot="{ open }"
          class="flex w-full"
          :class="[disabled ? 'cursor-not-allowed' : '']"
        >
          <div class="flex w-full px-6 py-3">
            <ComboboxInput
              :id="inputId"
              :name="name"
              class="peer block w-full appearance-none text-ellipsis focus:border-vesper-blue focus:outline-none"
              :class="[
                error
                  ? 'border-red-500 focus:border-red-500'
                  : 'border-vesper-zinc focus:border-vesper-blue',
                disabled ? 'cursor-not-allowed opacity-50' : '',
              ]"
              :display-value="itemLabel"
              placeholder=" "
              @input="handleChange"
              @blur="handleBlur"
              @change="searchQuery = $event.target.value"
            />
            <label
              :for="inputId"
              class="absolute left-4 top-2 z-10 origin-[14px] -translate-y-4 cursor-text bg-white px-2 text-xs/none font-normal duration-100 peer-placeholder-shown:top-[34px] peer-placeholder-shown:-translate-y-5 peer-placeholder-shown:text-sm/none peer-placeholder-shown:text-neutral-800/65 peer-focus:top-2 peer-focus:-translate-y-4 peer-focus:px-2 peer-focus:text-xs/none"
              :class="[
                error
                  ? 'text-red-500 peer-focus:text-red-500'
                  : 'text-vesper-neutral/65 peer-focus:text-vesper-neutral',
              ]"
            >
              {{ label }}
            </label>
            <div>
              <Icon
                icon="fa-regular fa-angle-down"
                class="text-base"
                :class="[disabled ? 'opacity-50' : '']"
                :flip="open ? 'vertical' : undefined"
              />
              <Icon
                v-if="error"
                icon="fa-solid fa-circle-exclamation"
                class="w-5 px-3 text-red-500"
                :flip="open ? 'vertical' : undefined"
              />
            </div>
          </div>
        </ComboboxButton>

        <ComboboxOptions
          class="z-20 mt-1 max-h-60 w-[325px] overflow-auto rounded-md border-transparent bg-vesper-mud text-sm/none focus:outline-none sm:text-sm"
        >
          <div
            v-if="filteredItems.length === 0 && searchQuery !== ''"
            class="relative cursor-default select-none px-6 py-2 text-gray-700"
          >
            {{ $t('common.no-matches-found') }}
          </div>

          <ComboboxOption
            v-for="item in filteredItems"
            :key="item"
            v-slot="{ selected }"
            :value="item"
            as="template"
          >
            <li
              class="relative cursor-pointer select-none px-6 py-2"
              :class="[
                selected
                  ? 'bg-vesper-neutral text-white'
                  : 'text-gray-900 hover:bg-vesper-zinc hover:text-vesper-neutral',
              ]"
            >
              {{ itemLabel(item) }}
            </li>
          </ComboboxOption>
        </ComboboxOptions>
      </Float>
    </Combobox>
  </div>
  <span v-if="errorMessage" class="text-xs text-red-500">
    {{ errorMessage }}
  </span>
</template>

<script setup lang="ts" generic="T">
import Float from '@/components/Float.vue';
import Icon from '@/components/Icon.vue';
import { useDropdown } from '@/composables';
import {
  Combobox,
  ComboboxButton,
  ComboboxInput,
  ComboboxOption,
  ComboboxOptions,
} from '@headlessui/vue';
import { useField } from 'vee-validate';
import { PropType, toRef } from 'vue';

const props = defineProps({
  name: {
    type: String,
    required: true,
  },
  inputId: {
    type: String,
    required: true,
  },
  items: {
    type: Array as PropType<string[] | T[]>,
    default: () => [],
  },
  modelValue: {
    type: [String, Array, Object] as PropType<string | string[] | object>,
    default: '',
  },
  allowEmpty: {
    type: Boolean,
    default: true,
  },
  multiple: {
    type: Boolean,
    default: false,
  },
  itemLabel: {
    type: Function as PropType<(item: T) => string>,
    default: (item: T) => item,
  },
  labelProp: {
    type: String,
    default: 'label',
  },
  label: {
    type: String,
    required: true,
  },
  error: {
    type: Boolean,
    default: false,
  },
  disabled: {
    type: Boolean,
    default: false,
  },
  searchProps: {
    type: Array as PropType<string[]>,
    default: () => ['name', 'description'],
  },
});

const emit = defineEmits(['update:modelValue']);

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const { searchQuery, filteredItems } = useDropdown<any>(
  props,
  emit,
  (item, query) => {
    if (typeof item === 'string') {
      return item.toLowerCase().includes(query);
    }

    return props.searchProps.some((prop) => {
      return (
        typeof item[prop] === 'string' &&
        item[prop].toLowerCase().includes(query)
      );
    });
  }
);

const itemLabel = (item: unknown): string => {
  return item ? props.itemLabel(item as T) : '';
};

// use `toRef` to create reactive references to `name` prop which is passed to `useField`
// this is important because vee-validate needs to know if the field name changes
// https://vee-validate.logaretm.com/v4/guide/composition-api/caveats
const name = toRef(props, 'name');

// we don't provide any rules here because we are using form-level validation
// https://vee-validate.logaretm.com/v4/guide/validation#form-level-validation
const { errorMessage, handleBlur, handleChange } = useField(name, undefined, {
  syncVModel: true,
});
</script>
