import { Api } from '@/api/types';
import { DealPreview, ReadCompanyDeal } from '@/types/deal';

export class Deal {
  public constructor(protected readonly client: Api) {}

  public getAllForCompany(): Promise<DealPreview[]> {
    return this.client.get('/marketplace/company/deals');
  }

  public getCompanyDealById(id: number): Promise<ReadCompanyDeal> {
    return this.client.get(`/marketplace/company/deals/${id}`);
  }
}
