<template>
  <Teleport to="body">
    <Modal
      v-if="listing && dealData"
      :is-open="isVisible"
      body-class="p-0"
      footer-class="p-0"
      @update:is-open="modalStore.hideModal()"
    >
      <template #header>
        <h1 class="text-lg/none">
          {{ $t('modal-accept-negotiation.congratulations') }}
        </h1>
      </template>
      <template #body>
        <CongratulationsCard />
        <ListingInfoCard :listing="listing" />
        <CompanyFactoryBanner
          v-if="listing.type === 'BID'"
          :listing="listing"
        />
        <TotalPrice :unit-price="dealData.price" :volume="dealData.volume" />
      </template>
      <template #footer>
        <div class="flex justify-end px-6 pb-6 pt-4 text-right">
          <Button variant="dark" @click="modalStore.hideModal()">
            <span>{{ $t('common.ok') }}</span>
          </Button>
        </div>
      </template>
    </Modal>
  </Teleport>
</template>

<script setup lang="ts">
import Button from '@/components/Button.vue';
import CompanyFactoryBanner from '@/components/CompanyFactoryBanner.vue';
import CongratulationsCard from '@/components/CongratulationsCard.vue';
import ListingInfoCard from '@/components/ListingInfoCard.vue';
import Modal from '@/components/Modal.vue';
import TotalPrice from '@/components/TotalPrice.vue';
import { useModalStore } from '@/stores';
import { computed } from 'vue';

const modalStore = useModalStore();

const isVisible = computed(() => modalStore.isVisible);
const dealData = computed(() => modalStore.eventData);
const listing = computed(() => dealData.value?.listing);
</script>
